import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/styles.scss'
import {Link} from "gatsby";
import {useState} from "react";
import Shield from '../images/inline/shield.inline.svg'
import Linkedin from '../images/inline/linkedin.inline.svg'
import ContactForm from "../components/contactForm";
// import {Router} from "@reach/router"


const CasosPage = ({location}) => {
    let section = '';
    const params = new URLSearchParams(location.search);
    const sections = ['manufactura', 'seguros', 'retail', 'otros']
    section = params.get('section') ?? sections[0]
    const [activeSection, setActiveSection] = useState(section)
    const [modalFormVisible, setModalFormVisible] = useState(false)

    function setSections(section: number) {
        setActiveSection(sections[section])
    }

    return (
        <Layout>
            <div className="casos-subtitles" data-sal-delay={200}
                 data-sal="slide-left"
                 data-sal-duration={"600"}>
                {sections.map((section, index) => {
                    return (
                        <div
                            key={'casos' + index} className={`item ${activeSection === section ? 'active' : ''}`}>
                            <Link
                                onClick={() => setSections(index)}
                                to={'/casos?section=' + section}><h2 className={'especial1'}>{section}</h2></Link>
                        </div>
                    )
                })}
            </div>
            <section className={`casos casos-seguros ${activeSection === 'seguros' ? 'active' : ''}`}>
                <div className="content active">
                    <div className="casos-first">
                        <div className="left">
                            <h4 className="especial1">
                                Creación y Control del Proceso Automatizado de Contratación, Emisión, Gestión y
                                Cobranza.
                            </h4>
                            <p className={'contenido1'}>
                                <Shield/>
                                <span>
                                Institución internacional con Subsidiaria en México, líder en el mercado de Seguros,
                                ofreciendo seguridad y tranquilidad financiera a sus clientes.
                            </span>
                            </p>
                        </div>
                        <div className="right">
                            <StaticImage src={'../images/casos_seguro_1.png'} alt={'Seguros'}/>
                        </div>
                    </div>
                    <div className="casos-flex">
                        <div className="casos-second">
                            <div className="content">
                                <h5 className={"especial2"}>Problemática</h5>
                                <p className={"contenido2"}>El Proceso para recolectar la documentación de Nuevos
                                    clientes era muy lento, principalmente de los de Provincia. Se complicaba con la
                                    verificación de los documentos obligatorios, así como con la gran cantidad de otra
                                    información complementaria.</p>

                                <p className={"contenido2"}>Situación similar con la administración de documentos
                                    complementarios una vez emitida la Póliza de Seguro.</p>
                                <p className={"contenido2"}>También se encontraba desarticulado el Control de Cobros y
                                    Siniestros.</p>
                            </div>
                        </div>
                        <div className="casos-fourth">
                            <div className="content">
                                <div className="left">
                                    <h5 className="especial2">
                                        Solución
                                    </h5>
                                    <p className="contenido2">Se implementó un sistema de Administración Documental (DMS),
                                        intercambiando información con el Sistema existente de Generación de Pólizas,
                                        con el fin de obtener:</p>
                                    <ul>
                                        <li className={'contenido2'}>Expediente Único por cliente.</li>
                                        <li className={'contenido2'}>Identificación de documentación obligatoria
                                            recibida y spor tanto, documentos faltantes.
                                        </li>
                                        <li className={'contenido2'}>Integración con Sistema Central de Emisión de
                                            Pólizas.
                                        </li>
                                        <li className={'contenido2'}>Control de documentos Post Emisión.</li>
                                        <li className={'contenido2'}>Administración de cobros y siniestros.</li>
                                        <li className={'contenido2'}>Migración de la información histórica existente.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="casos-third">
                        <h5>Digitalización de procesos end to end</h5>
                        <div className="imgs">
                            <div className="main">
                                <StaticImage className={'img'} src={'../images/caso_seguros.png'} alt={'Seguros'}/>
                            </div>
                            <StaticImage className={'printer'} src={'../images/printer.png'} alt={'Printer'}/>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`casos casos-retail ${activeSection === 'retail' ? 'active' : ''}`}>
                <div className="content-active">
                    <div className="casos-first">

                        <div className="left">
                            <h4 className="especial1">Automatización del Proceso de Devoluciones de Mercancía de los
                                Clientes.</h4>
                            <p className={'contenido1'}>
                                <Shield/>
                                <span>
                                    Empresa internacional con Subsidiaria en México, líder e innovador en el mercado de
                                    ropa de moda deportiva, calzado, accesorios, perfumería, entre otros, para todas las
                                    Generaciones y Culturas.
                                </span>
                            </p>
                        </div>
                        <div className="right">
                            <StaticImage className={'img'} src="../images/dibujo-admin-left.png" alt="ALEMAR"/>
                        </div>
                    </div>
                    <div className="casos-flex">
                        <div className="casos-second">
                            <div className="content">
                                <h5 className={"especial2"}>Problemática</h5>

                                <p className={"contenido2"}>La falta de una correcta administración, agilidad y
                                    eficiencia en el Proceso de Devoluciones de Cliente, repercutía en no contar con una
                                    cartera sana ya que el cliente en ocasiones detenía el pago si no ve reflejada su
                                    Nota de Crédito, afectando el flujo de efectivo.</p>

                                <p className={"contenido2"}>Discrepancias en la documentación y cantidad de productos en
                                    las Devoluciones, creando retrabajos, falta de visibilidad y métricas de control,
                                    información desactualizada, trabajos manuales e ineficientes con archivos en Excel y
                                    altos costos de operación.</p>
                            </div>
                        </div>
                        <div className="casos-fourth">
                            <div className="content">
                                <div className="left">
                                    <h5 className="especial2">
                                        Solución
                                    </h5>
                                    <p className="contenido2">Se implementaron Flujos de Trabajo (Workflows)
                                        integrados en una Solución de Software de Administración de Documentos
                                        (DMS):</p>
                                    <ul>
                                        <li className={'contenido2'}>Optimización y automatización del flujo del
                                            Proceso de Devoluciones mediante Workflows e Intercambio de información
                                            con el Sistema ERP
                                        </li>
                                        <li className={'contenido2'}>Estandarización de los diferentes formatos de
                                            Solicitud, según el tipo de Cliente
                                        </li>
                                        <li className={'contenido2'}>Comparación automática entre la Solicitud del
                                            Cliente y lo recibido físicamente, identificando discrepancias en
                                            Productos (SKUs), cantidad de piezas e importes de Venta
                                        </li>
                                        <li className={'contenido2'}>Automatización de la generación y flujo de
                                            Autorizaciones de Notas de Crédito
                                        </li>
                                        <li className={'contenido2'}>Visibilidad y Control total de los pasos del
                                            Proceso de Devoluciones
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="casos-third">
                        <h5>“Se implementaron flujos de trabajo integrados en una solución de Software de Administración
                            de Documentos (DMS)”</h5>
                        <div className="imgs">
                            <div className="main">
                                <StaticImage className={'img'} src={'../images/caso_retail.jpg'} alt={'Retail'}/>
                            </div>
                            <StaticImage className={'printer'} src={'../images/printer.png'} alt={'Printer'}/>
                        </div>
                    </div>

                </div>
            </section>

            <section className={`casos casos-manufactura ${activeSection === 'manufactura' ? 'active' : ''}`}>

                <div className={`content active`}>
                    <div className="casos-first">

                        <div className="left">
                            <h4 className="especial1">
                                Mejoras e Integración de Order to Cash.
                            </h4>
                            <p className={'contenido1'}>
                                <Shield/>
                                <span>
                                    Empresa en México, líder en el mercado europeo y gran
                                    presencia en Américas. Dedicada a la fabricación, venta y distribución de caramelos
                                    y golosinas, confitería y gomas de mascar.
                                </span>
                            </p>
                        </div>
                        <div className="right">
                            <StaticImage src={'../images/casos_retail_1.png'} alt={'Seguros'}/>
                        </div>
                    </div>
                    <div className="casos-flex">
                        <div className="casos-second">
                            <div className="content">
                                <h5 className={"especial2"}>Problemática</h5>
                                <p className="contenido2">El proceso de Order to cash (Logística Supply Chain) se
                                    encontraba
                                    desarticulado y sin contar con la documentación requerida e interconexión con el
                                    sistema
                                    ERP.</p>
                                <p className="contenido2">La infromación requerida para auditorías estaba en condiciones
                                    físicas y electrónicas sin un método constante de identificación y
                                    categorización.</p>
                                <p className="contenido2">Asimismo se encontraban en similar situación los procesos de
                                    Procure to Pay (compras), finanzas y comerciio exterior.</p>
                            </div>
                        </div>
                        <div className="casos-fourth">
                            <div className="content">
                                <div className="left">
                                    <h5 className="especial2">
                                        Solución
                                    </h5>
                                    <p className="contenido2">Se implementaron flujos de trabajo (workflows) integrados
                                        a
                                        una solución de software de administración de documentos (DMS) e interconectados
                                        con
                                        el ERP:</p>
                                    <ul>
                                        <li className={'contenido2'}>Almacén.</li>
                                        <li className={'contenido2'}>Planeación.</li>
                                        <li className={'contenido2'}>Compras (Proceso Procure to Pay).</li>
                                        <li className={'contenido2'}>Finanzas (CXC & CXP, Cierre de contratos).</li>
                                        <li className={'contenido2'}> Pedimentos Exportaciones / Importaciones.</li>
                                        <li className={'contenido2'}>Reportes de movimientos y trazabilidad
                                            (Auditoría).
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="casos-third">
                        <h5>“Reportes de movimientos y trazabilidad (auditoría)”</h5>
                        <div className="imgs">
                            <div className="main">
                                <StaticImage className={'img'} src={'../images/caso_manufactura.jpg'} alt={'Manufactura'}/>
                            </div>
                            <StaticImage className={'printer'} src={'../images/printer.png'} alt={'Printer'}/>
                        </div>
                    </div>

                </div>

            </section>

            <section className={`casos casos-otros ${activeSection === 'otros' ? 'active' : ''}`}>
                <div className="content-active">
                    <div className="casos-first">

                        <div className="left">
                            <h4 className="especial1">
                                Automatización del Proceso de Generación y Control de Notas de Crédito.
                            </h4>
                            <p className={'contenido1'}>
                                <Shield/>
                                <span>
                                    Empresa internacional con subsidiaria en México, líder en el mercado europeo y gran
                                    presencia en Américas. Dedicada a la fabricación, venta y distribución de artículos
                                    deportivos especializados y accesorios para utilizar dentro y fuera de la cancha de
                                    juego.
                                </span>
                            </p>
                        </div>
                        <div className="right">
                            <StaticImage className={'img'} src="../images/Group-149.png" alt="Servicios ALEMAR"/>
                        </div>
                    </div>
                    <div className="casos-flex">
                        <div className="casos-second">
                            <div className="content">
                                <h5 className={"especial2"}>Problemática</h5>
                                <p className="contenido2">Esta empresa presentaba 2 diferentes situaciones:</p>
                                <p className="contenido2">El proceso de autorizaciones para movimientos y salida de
                                    productos del almacén presentaba, entre otros, el reto de que los Ejecutivos no
                                    estaban
                                    presentes o disponibles, causando retrasos en las entregas de pedidos. Tampoco se
                                    contaba a tiempo con la documentación de entrega de parte del cliente.</p>
                                <p className="contenido2">El proceso de contratación de personal de nuevo ingreso
                                    implicaba
                                    el reto de integrar ágilmente la documentación.</p>
                            </div>
                        </div>
                        <div className="casos-fourth">
                            <div className="content">
                                <div className="left">
                                    <h5 className="especial2">
                                        Solución
                                    </h5>
                                    <p className="contenido2">Se implementaron flujos de trabajo (workflows) integrados
                                        a una solución de software de administración de documentos (DMS):</p>
                                    <ul>
                                        <li className={'contenido2'}>Proceso de autorizaciones, permitiendo el acceso
                                            desde
                                            cualquier lugar y dispositivo, en base a los permisos y niveles de firma..
                                        </li>
                                        <li className={'contenido2'}>Planeación.</li>
                                        <li className={'contenido2'}>Almacén - Mejoras al proceso de logística e
                                            integración
                                            a la solución de las evidencias de transportistas.
                                        </li>
                                        <li className={'contenido2'}>RRHH - Integración del Expediente electrónico único
                                            por
                                            empleado para el proceso de on boarding.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="casos-third">
                        <h5>“Proceso de autorizaciones, permitiendo el acceso desde cualquier lugar y dispositivo.”</h5>
                        <div className="imgs">
                            <div className="main">
                                <StaticImage className={'img'} src={'../images/casos_retail_2.jpg'} alt={'Seguros'}/>
                            </div>
                            <StaticImage className={'printer'} src={'../images/printer.png'} alt={'Printer'}/>
                        </div>
                    </div>

                </div>
            </section>

            <div className="casos-bottom">
                <div className="content">
                    <button className={'btn-push-black'}
                       onClick={() => setModalFormVisible(true)} ><span>Agenda tu Asesoría</span></button>
                    <div className="follow-us">
                        <h5 className="especial2">Síguenos en</h5>
                        <div className="redes">
                            <a href="/">
                                <Linkedin/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {modalFormVisible &&
                <div className={'modal form-contact'}>
                    <div className="bg-black content">
                        <button onClick={() => setModalFormVisible(false)}>x</button>
                        <ContactForm
                            asunto={'Me gustaría una asesoría sobre servicios de servicios administrados de impresión'}/>
                    </div>
                </div>
            }

        </Layout>
    )
}

export default CasosPage


export const Head = () => <title>T.I.O. ALEMAR | Casos de Éxito</title>